@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@font-face {
  font-family: "NeueAlteGrotesk-ExtraLight";
  src: url(../../static/media/font.93677213.woff2) format('woff2'), url(../../static/media/font.2478d482.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Regular";
  src: url(../../static/media/font.6fe7ca24.woff2) format('woff2'), url(../../static/media/font.484f8516.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-SemiBold";
  src: url(../../static/media/font.06312977.woff2) format('woff2'), url(../../static/media/font.e83c6ce4.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Medium";
  src: url(../../static/media/font.618e2580.woff2) format('woff2'), url(../../static/media/font.23d7c6e1.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Light";
  src: url(../../static/media/font.3f95e9e6.woff2) format('woff2'), url(../../static/media/font.bbb62fe9.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Black";
  src: url(../../static/media/font.68d450d0.woff2) format('woff2'), url(../../static/media/font.5388d69a.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Heavy";
  src: url(../../static/media/font.714e273f.woff2) format('woff2'), url(../../static/media/font.9bf7ad4b.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Bold";
  src: url(../../static/media/font.de1879b0.woff2) format('woff2'), url(../../static/media/font.b4938aa1.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Thin";
  src: url(../../static/media/font.e594fd02.woff2) format('woff2'), url(../../static/media/font.d372ccf9.woff) format('woff');
}
@font-face {
  font-family: "NeueAlteGrotesk-Book";
  src: url(../../static/media/font.9f95cc86.woff2) format('woff2'), url(../../static/media/font.8a0a59e1.woff) format('woff');
}

body {
  margin: 0;
  font-family: NeueAlteGrotesk-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: NeueAlteGrotesk-Regular;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

.ant-pagination-item, .ant-pagination-item-active {
  border-color: transparent;
}

ul.ant-pagination li a.ant-pagination-item-link {
  display: inline !important;
  display: initial !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #1321b1;
}
.ant-picker-focused {
  border-color: #1321b1;
}
.ant-picker-today-btn {
  color: #1321b1;
}
.ant-picker-today-btn:hover {
  color: #1321b1;
}
.ant-picker-today-btn:active {
  color: #1321b1;
}

.ant-picker-range .ant-picker-active-bar {
  background: #1321b1;
  margin-left: -2px;
  padding: 0 4px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #1321b1;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #1321b1;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed #1321b1;
  border-bottom: 1px dashed #1321b1;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #1321b1;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #1321b1;
}

.ant-switch-checked {
  background-color: #1321b1;
}

.ant-spin-dot-item {
  background-color: #00c197;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1321b1;;
  border-color: #1321b1;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1321b1;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #1321b1;
}

.ant-picker {
  padding: 0;
}

.ant-picker-range .ant-picker-clear {
  right: 0;
}

.ant-picker-input > input {
  text-align: center;
  background: white;
  border-radius: 14px;
  padding: 5px 0;
}

.ant-picker-suffix{
  margin-left: 9px;
}

#svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -999999;
}


.point{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 99999;
  background: red;
  position: absolute;
}

#one{
  top: 360px;
  left: 340px;
}

#two{
  top: 360px;
  left: 590px;
}

#three {
  top: 580px;
  left: 590px;
}
#corporation_type_svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -999999;
}


.point{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 99999;
  background: red;
  position: absolute;
}

#corporation_type_one{
  top: 360px;
  left: 340px;
}

#corporation_type_two{
  top: 360px;
  left: 590px;
}

#corporation_type_three {
  top: 580px;
  left: 590px;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1;
  padding-left: 4px;
  margin-bottom: 10px;
}

.ant-popconfirm{
  z-index: 9999999;
}

.ant-dropdown-menu {
  padding: 0;
  border-radius: 8px;
  min-width: 108px;
}

